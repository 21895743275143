<template>
	<div class="user flex main other_main">
		<asiden :asideData="asideList"></asiden>
		<div class="main_r">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import asiden from "../layout/aside/aside.vue";
export default {
	components: { asiden },
	name: "",
	data() {
		return {
			asideList: [
				{
					title: "资讯列表",
					icon: "",
					router: "/message/messagelist",
				},
				{
					title: "新增资讯",
					icon: "",
					router: "/message/addmessage",
				},
				{
					title: "快讯模版",
					icon: "",
					router: "/message/flashpc",
				},
				{
					title: "类别管理",
					icon: "",
					router: "/message/category",
				},

				{
					title: "专题管理",
					icon: "",
					router: "/message/special",
				},
				{
					title: "标签管理",
					icon: "",
					router: "/message/label",
				},
				{
					title: "公告管理",
					icon: "",
					router: "/message/announcement",
				},
				{
					title: "工具管理",
					icon: "",
					router: "/message/tools",
				},
				{
					title: "恢复数据",
					icon: "",
					router: "/message/dellist",
				},
			],
		};
	},
};
</script>

<style scoped></style>
